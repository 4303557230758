import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import Home from "../components/Home";
import ContactUs from "../components/ContactUs";

function RoutesWeb() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
    </BrowserRouter>
  );
}

export default RoutesWeb;
