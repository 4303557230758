import Container from "../Container";

interface TitleSectionProps {
    title: string;
    id: string;
}



function TitleSection({ title, id } : TitleSectionProps) {
    return (
        <Container>
            <div id={id} className="py-12 text-white md:m-0 ml-6">
                <h2 className="text-4xl font-normal	">{title}</h2>
            </div>
        </Container>
    )
}

export default TitleSection;