import familyF from '../../assets/images/capa.svg'
import Container from '../Container';

function Intro() {
    return (
        <div className="bg-gradient-to-r from-[#e21d19] via-[#FE6665] to-[#e21d19]">
            <Container>
                <div className="grid grid-cols-1 md:grid-cols-2 py-14 px-11">
                    <div 
                    className="flex items-center"
                    >
                        <h1 className="text-4xl leading-loose text-white">
                            Melhor 
                            <br/>
                            Morango do 
                            <br/>
                            Distrito Federal
                        </h1>
                    </div>
                    <div className="flex m- items-center justify-end">
                        <img className="w-[30rem] object-contain" src={familyF} alt="Ilustração da familia F" />
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Intro;