import { ChangeEvent, useState } from "react";
import useErrors from "../../hooks/useErrors";
import isEmailValid from "../../utils/isEmailValid";
import maskPhoneNumber from "../../utils/maskPhoneNumber";
import FormGroup from "../FormGroup";
import SendSuccess from "../SendSuccess";

interface HandleSendEmailProps {
  email: string;
  name: string;
  phoneNumber: string;
  message: string;
}


interface FormSendMailProps {
  onSubmit: (props: HandleSendEmailProps) => Promise<boolean>;
}

function FormSendMail({ onSubmit }: FormSendMailProps) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [sendSuccess, setSendSucess] = useState(false);
  const { removeError, setError, getErrorMessageByFieldName, errors} = useErrors();


  async function handleSubmit(event: ChangeEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();
    
    if(!name) {
      setError({
        field: 'name',
        message: 'Campo nome é obrigatório!'
      })
    }
    
    if(!email) {
      setError({
        field: 'email',
        message: 'Campo email é obrigatório!'
      })
    }
    
    if(!phoneNumber) {
      setError({
        field: 'phoneNumber',
        message: 'Campo telefone é obrigatório!'
      })
    }
    
    if(name && email && phoneNumber && errors.length <= 0){
      const response = await onSubmit({
        email, message, name, phoneNumber
      })
      setSendSucess(response);
    }
  }

  function handleChangeName(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);

    if(!event.target.value) {
      setError({
        field: 'name',
        message: 'Campo nome é obrigatório!'
      })
    } else {
      removeError('name');
    }
  }

  function handleChangePhoneNumber(event: ChangeEvent<HTMLInputElement>) {
    setPhoneNumber(maskPhoneNumber(event.target.value));

    if(!event.target.value) {
      setError({
        field: 'phoneNumber',
        message: 'Campo telefone é obrigatório!'
      })
    } else {
      removeError('phoneNumber');
    }
  }

  function handleChangeEmail(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);

    if(event.target.value && !isEmailValid(event.target.value)) {
      setError({
        field: 'email',
        message: 'E-mail inválido!'
      })
    } else {
      removeError('email');
    }
  }

  function handleChangeMessage(event: ChangeEvent<HTMLTextAreaElement>) {
    setMessage(event.target.value);
  }

  if(sendSuccess) {
    return (
      <SendSuccess />
    )
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      <p className="mb-4">Campos marcados com * requeridos</p>

      <FormGroup error={getErrorMessageByFieldName('name')} label="Nome" required>
        <input
            value={name}
            onChange={handleChangeName}
            className="p-2  rounded"
            type="text"
            placeholder="nome"
          />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('phoneNumber')} label="Telefone" required>
        <input
            type="tel"
            onChange={handleChangePhoneNumber}
            value={phoneNumber}
            className="p-2 rounded"
            placeholder="telefone"
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('email')} label="Email" required>
        <input
          className="p-2 rounded"
          type="email"
          onChange={handleChangeEmail}
          value={email}
          placeholder="email"
        />
      </FormGroup>

      <FormGroup label="Message" required={false}>
        <textarea
          className="rounded"
          onChange={handleChangeMessage}
          value={message}
          cols={30}
          rows={10}
        ></textarea>
      </FormGroup>

      <div className="py-10">
        <button  type="submit" className="bg-slate-700 rounded-3xl p-3 text-white">
          Enviar
        </button>
      </div>
    </form>
  );
}

export default FormSendMail;
