import React from "react";

interface ContainerProps {
    children: React.ReactNode;
}


function Container({children} : ContainerProps) {
    return (
        <div className="container max-w-6xl my-0 mx-auto">
            {children}
        </div>
    )
}

export default Container;