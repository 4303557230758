import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/familia-f-logo.png";
import Menu from "../../assets/images/menu-outline.svg";
import Close from "../../assets/images/close-outline.svg";

interface IPath {
  contactUs: {
    isActive?: boolean;
    path?: string;
    className?: string;
  };
  home: {
    isActive?: boolean;
    path?: string;
    className?: string;
  };
}

function Header() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [paths, setPaths] = useState<IPath>();

  function handleOpenMenu() {
    setOpen((prevState) => !prevState);
  }

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === "/contact-us") {
      setPaths({
        home: {
          isActive: false,
        },
        contactUs: {
          isActive: true,
          path: "/contact-us",
          className: "text-red-500",
        },
      });
    } else if (currentPath === "/") {
      setPaths({
        contactUs: {
          isActive: false,
        },
        home: {
          isActive: true,
          path: "/",
          className: "text-red-500 ",
        },
      });
    }
    console.log(currentPath);
  }, [location]);

  return (
    <nav className="bg-white container max-w-6xl my-0 mx-auto font-display">
      <div className="flex justify-between">
        <div >
          <Link to="/" className="w-auto" >
            <img className="w-44" alt="Logo da empresa Familia F" src={Logo} />
          </Link>
        </div>
        
        <div className="flex-1 md:flex justify-end hidden">
          <ul className="flex items-center">
            <li className="px-4">
              <Link to="/" className={`${paths?.home.className} hover:text-red-400`}>
                Início
              </Link>
            </li>

            {location.pathname !== '/contact-us' ? (
              <>
                <li className="px-4">
                  <a className="hover:text-red-400" href="#aboutUs">Sobre</a>
                </li>

                <li className="px-4">
                  <a className="hover:text-red-400" href="#carousel">Galeraia</a>
                </li>
              </>
            ): (
              <>
                <li className="px-4">
                  <Link to="/#aboutUs" className={`${paths?.home.className} hover:text-red-400`}>
                    Sobre
                  </Link>
                </li>

                <li className="px-4">
                  <Link to="/#carousel" className={`${paths?.home.className} hover:text-red-400`}>
                    Galeria
                  </Link>
                </li>
              </>
            )}
        
            <li className="px-4">
              <Link 
                to="/contact-us" 
                className={`${paths?.contactUs.className} transition duration-1000	 text-white font-bold hover:bg-green-500 hover:text-red-600 bg-red-600 py-4 px-10 rounded-full	`}
              >
                Contatos
              </Link>
            </li>
          </ul>
        </div>

        <div className="flex-1 md:hidden justify-end items-center flex">
          <span onClick={handleOpenMenu}>
            <img className="h-10 text-white bg-red-600 rounded mr-4 fill-gray-400	" src={open === true ? Close : Menu} alt="" />
          </span>
        </div>
      </div>

      {open && (
        <div className="flex-1 md:hidden justify-end bg-slate-300/20 ">
          <ul>
            <li className="p-2 border-y hover:text-red-400">
              <Link to="/" className={paths?.home.className}>
                Início
              </Link>
            </li>
            <li className="p-2 border-y hover:text-red-400">
              <a href="#aboutUs">Sobre</a>
            </li>
            <li className="p-2 border-y hover:text-red-400">
              <a href="#carousel">Galeria</a>
            </li>
            <li className="p-2 border-y hover:text-red-400">
              <Link to="/contact-us" className={`${paths?.contactUs.className} hover:text-red-400`}>
                Contato
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Header;
