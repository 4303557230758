import Container from "../Container";
import Footer from "../Footer";
import Header from "../Header";
import phone from "../../assets/images/phone.svg";
import mailOutline from "../../assets/images/mail-outline.svg";
import logoWhatsapp from "../../assets/images/logo-whatsapp.svg";
import Logo from '../../assets/images/familia-f-de-familia-para-familia.svg';
import FormSendMail from "../FormSendMail";


interface HandleSendEmailProps {
  email: string;
  name: string;
  phoneNumber: string;
  message: string;
}

function ContactUs(): JSX.Element {
    async function handleSendEmail({ email, message, name, phoneNumber} :HandleSendEmailProps): Promise<boolean> {
      try {
        const headers = new Headers({
          'Content-Type': 'application/json',
        });

        const body = JSON.stringify({
          email,
          name,
          phoneNumber,
          message,
        })

        await fetch('http://localhost:3001/send-message', {
          method: 'POST',
          body,
          headers,
        })

        return true;
      } catch {
        return false;
      }
    }

    return (
        <div className="flex flex-col h-screen justify-between">
            <Header />
            <div className="bg-emerald-300 font-display text-white flex-1"> 
                <Container>
                    <div className="grid md:grid-cols-3 md:px-0 px-2 gap-6 py-14 text-center">
                        <div className="bg-red-600 rounded-2xl flex flex-col p-7">
                            <img src={phone} className="h-10" alt=""  />
                            <span className="text-xl mb-5">Telefone</span>
                            <span>(61) 9 9901-1266</span>
                        </div>

                        <div className="bg-red-600 rounded-2xl flex flex-col p-7">
                            <img src={mailOutline} className="h-10" alt=""  />
                            <span className="text-xl mb-5">E-mail</span>
                            <span>familiafmorangos@gmail.com</span>
                        </div>

                        <div className="bg-red-600 rounded-2xl flex flex-col items-center p-7">
                            <img src={logoWhatsapp} className="h-10" alt=""  />
                            <span className="text-xl mb-5">WhatsApp</span>
                            <a 
                                href="https://bit.ly/contatofamiliaf"
                                target="blank"
                                className="bg-emerald-600 py-2 w-36	 
                                inline-block rounded-xl border-double color border-neutral-50 border-8"
                            >
                                Clique Aqui
                            </a>
                        </div>
                    </div>

                    <div className="text-black font-bold px-2">
                    
                        <div className="grid md:grid-cols-2 grid-cols-1">
                            <FormSendMail onSubmit={handleSendEmail} />

                            <div className="md:mt-20 mt-0">
                                <img
                                    className="w-84" 
                                    alt="Logo da empresa Familia F" 
                                    src={Logo} 
                                /> 
                            </div>
                        </div>
                    </div>

                </Container>

            </div>

            <Footer />
        </div>
    )
}

export default ContactUs;