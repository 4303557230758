import AboutUs from "../AboutUs";
import BackgroundRed from "../BackgroundRed";
import Carousel from "../Carousel";
import Footer from "../Footer";
import Header from "../Header";
import Intro from "../Intro";
import TitleSection from "../TitleSection";

function Home() {
    return (
        <>
            <Header />
            <BackgroundRed>
            <Intro />
            <TitleSection id="aboutUs" title="Sobre Nós"/>
            <AboutUs />
            <TitleSection id="carousel" title="Galeria" />
            <Carousel />
            <Footer />
            </BackgroundRed>
        </>
    )
}

export default Home;