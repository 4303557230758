import Container from "../Container";
import LogoFacebook from '../../assets/images/logo-facebook.svg'
import LogoInstagram from '../../assets/images/logo-instagram.svg'

function Footer() {
    return (
        <footer className="bg-red-600 font-normal py-5">
            <Container>
                    <div className="grid md:grid-cols-2">
                        <div className="flex md:justify-start justify-center items-center">
                            <p className="text-white">Copyright © 2022 Família F | Oriente-se</p>
                        </div>

                        <div className="flex md:justify-end  justify-center">
                            <a target="blank" href="https://www.facebook.com/morangosfamiliaf/">
                                <img src={LogoFacebook} alt="" className="h-9 m-1 text-white" />
                            </a>
                            <a target="blank" href="https://www.instagram.com/morangosfamiliaf/">
                                <img  src={LogoInstagram} alt="" className="h-9  m-1 text-white" />
                            </a>
                        </div>
                        
                    </div>
            </Container>
        </footer>
    )
}

export default Footer;