import Routes from './routes/routes';


function App() {
  return (
    <Routes />
  );
}

export default App;
