import React from "react";


interface BackgroundRedProps {
    children: React.ReactNode;
}


function BackgroundRed({children} : BackgroundRedProps) {
    return (
        <div className="bg-red-400  font-display font-bold">
            {children}
        </div>
    )
}
export default BackgroundRed;