import icon from "../../assets/images/icon.svg";
import woman from "../../assets/images/woman.svg";
import men from "../../assets/images/men.svg";
import womandAndkid from "../../assets/images/woman-kid.svg";
import Container from "../Container";

function AboutUs() {
  return (
    <Container>
      <div className="grid gap-10 grid-cols-1 md:grid-cols-2 items-center text-center md:text-xl">
        <div className="md:order-1 order-1">
          <img
            className="w-[30rem] object-contain"
            src={icon}
            alt="Ilustração da familia F"
          />
        </div>

        <div className="md:order-2 order-2">
          <p>
            A sigla ‘’F’’ vem das iniciais dos nomes dos 8 filhos de Sr.
            Francisco e Dona Maria. Esses filhos que desde o início ajudaram
            seus pais a plantar e vender morangos
          </p>
        </div>

        <div className="md:order-3 order-4">
          <p>
            Em meados de 2003 Sr. Franciso, sua esposa Maria e os 8 filhos
            saíram do interior de São Paulo para Brasília em busca de melhores
            condições de vida. Chegaram no DF e começaram trabalhando em
            plantações de verduras e hortaliças na região de Brazlândia, uma
            pequena cidade que conta com grande produção agrícola, sendo
            considerada umas das maiores produtoras de morango da região. A
            família do Sr.Francisco encarou variados problemas até atingirem uma
            boa qualidade de produção. Por não saberem plantar em um clima
            totalmente diferente de São Paulo e não terem uma moradia própria,
            Francisco e Maria enfrentaram muitos desafios para poderem alimentar
            seus 8 filhos. No início Sr. Francisco e seus filhos mais velhos,
            por muito tempo serviram apenas como mão de obra braçal nas lavouras
            de Brazlândia.
          </p>
        </div>

        <div className="md:order-4 order-3">
          <img
            className="w-[30rem]  object-contain"
            src={woman}
            alt="Ilustração da familia F"
          />
        </div>

        <div className="md:order-5 order-5">
          <img
            className="w-[30rem] object-contain"
            src={men}
            alt="Ilustração da familia F"
          />
        </div>

        <div className="md:order-6 order-6">
          <p>
            Como a família só participava da produção, eles não tinham controle
            das vendas de hortaliças e verduras, isso fazia com que seus
            “atravessadores”, ( negociante que está entre o produtor e o
            comerciante varejista), por muitas vezes não repassasse o valor
            justo pela plantação e cultivo. Esse foi um dos motivos que fizeram
            a família batalhar e com muito esforço conseguiram um terreno
            próprio, para ali iniciarem uma nova fase para a Família F.
          </p>
        </div>

        <div className="md:order-7 order-8">
          <p>
            Dia após dia, todo família se reunia, para que cada vez mais
            atingissem a excelência em sua produção. Todo esse talento deve-se a
            essência e virtudes por eles construídas. A empresa Família F chegou
            ao patamar de ter o morango mais exclusivo da região, sendo
            considerado por 3 anos consecutivos o melhor do DF desde 2017 e tudo
            isso aconteceu em decorrência da união e humildade da família do Sr.
            Francisco e Sr. Maria.
          </p>
        </div>

        <div className="md:order-8 order-7">
          <img
            className="w-[30rem] object-contain"
            src={womandAndkid}
            alt="Ilustração da familia F"
          />
        </div>
      </div>
    </Container>
  );
}

export default AboutUs;
