import React from "react";

interface FormGroupsProps {
  children: React.ReactNode;
  label: string;
  required: boolean;
  error?: string;
}

function FormGroup({children, label, required, error} : FormGroupsProps) {
  return (
    <div>
        <div className="flex flex-col mb-2">
          <label>
            {label}: {required && (<span className="text-red-600">*</span>)}
          </label>
              {children}

          {error && <small className="text-red-600 mt-1">{error}</small>}
        </div>
    </div>
  )
}

export default FormGroup;