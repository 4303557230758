import ImageGallery from 'react-image-gallery';
import familyOld from '../../assets/images/family-old.svg';
import familyNew from '../../assets/images/family-new.svg';
import familyTwo from '../../assets/images/family-two.svg';
import Fathers from '../../assets/images/Fathers.svg';
import FatherAndBrothers from '../../assets/images/FatherAndBrothers.svg';
import brothers from '../../assets/images/brothers.svg';
import Container from '../Container';


const images = [
  {
    original: familyOld,
  },
  {
    original: familyNew,
  },
  {
    original: familyTwo,
  },
  {
    original: Fathers,
  },
  {
    original: FatherAndBrothers,
  },
  {
    original: brothers,
  },
];

function Carousel() {
    return (
      <Container>
        <div className="md:py-12 py-0">
          <ImageGallery slideInterval={3000} showThumbnails={false} items={images} />
        </div>
      </Container>
    )
}

export default Carousel;